.App {
  text-align: center;
}

.App-header {
  /* background-color: whitesmoke; */
  /* min-height: 100vh; */
  color: black;
  z-index: 100;
}

.header{
  /* background-color: rgba(1,86,179,0.95); */
  /* background-color: #007bff; */
  /* (0, 51, 102), (128, 0, 32), (34, 139, 34), (75, 0, 130)  */
  /* background-color: rgba(119,136,153,0.4); */
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 110;
  position: relative;
}

.user-commands {
  color: white;
  z-index: 110;
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 2vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

.logo {
  height: 6vh;
  cursor: pointer;
  /* pointer-events: none;
  padding-top: 0.3vh;
  cursor: none;
  position: relative;
  z-index: 150;
  align-items: center;
  justify-content: center; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.menuTitle {
  position: inherit;
  margin-top: -3vh;
  width: 20vw;
  height: 8vh;
  border-bottom: 1px solid white;
  cursor: default;

}

.sandwichMenu { 
  position: absolute;
  max-height: 10vh;
  top: 2vh;
  left: 0;
  background: none;
  border: none;
  font-size: 1.7em;
  cursor: pointer;

}

.menuContainer {
  position: absolute;
  background-color: grey;
  top: 0;
  left: 0;
  width: 20vw;
  height: 100vh;
}

.menu {
  position: absolute;
  top: 5vh;
  
}

.bar {
  color: black;
  cursor: pointer;
  padding-left: 3vw;  
  /* text-align: center; */
}

.close-button {
  position: absolute;
  top: 1vh;
  right: 2vh;
  color: rgba(0, 0, 0, 1);
  cursor: pointer;
}

/* Start of register property component */



.register-property .userForm {
  max-width: 350px;
  margin: 0 auto;
  padding: 20px;
}

.register-property-heading {
  padding-top: 5vh;
}

.register-property .userForm label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  width: 100%;
}

.register-property label input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 400px;
}


.register-property .button {
  align-self: flex-end;
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


/* phone support for the register property component */
@media (min-width: 768px) {
  .register-property label .userForm {
      flex-direction: row;
      align-items: center;
  }

  .register-property label span {
      flex: 0 0 120px;
      margin-bottom: 0;
  }

  .register-property label input {
      flex: 1;
      max-width: 400px;
  }

  .register-property .button {
      align-self: flex-end;
  }
}

/* Styling for the label */
label {
  display: block;
  margin: 10px 0;
  font-family: Arial, sans-serif;
}

/* Styling for input */
label input {
  width: 50%;
  padding: 8px 10px;
  margin: 5px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Styling for dropdown listu */
label div {
  position: relative;
}

label div div {
  position: absolute;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-top: none;
  box-sizing: border-box;
  z-index: 1000;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

label div div:hover {
  background-color: #f1f1f1;
}

.table {
  /* display: block;
  margin-left: auto;
  margin-right: auto; */
  margin-left: auto;
  margin-right: auto;
  
  max-width: 60vw;
  /* width: 60vw; */
  border-collapse: collapse;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.table th, .table td {
  padding: 5px;
  border: 1px solid #ddd;
  text-align: left;
}

.table thead {
  background-color: #f4f4f4;
}

.table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table tr:hover {
  background-color: #f1f1f1;
}

.button {
  background-color: #007bff; /* Zelena boja za akciju */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px; /* Blago zaobljeni rubovi */
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease; /* Prijelaz za hover efekt */
  outline: none; /* Uklanja outline na focus */
  /* margin: 10px; */
  
}

.button:hover {
  background-color: #0056b3; /* Tamnija nijansa za hover */
}

.button:focus {
  box-shadow: 0 0 0 2px rgba(128, 189, 255, 0.5); /* Dodaje sjenu za fokus */
}

.button:disabled{
  background-color: #cccccc;
  color: #666666;
  cursor: default;

}

.reset-pw {
  background-color: #4CAF50; /* Zelena boja za akciju */
  color: white;
  margin-top: 2px;
  padding: 12px 20px;
  border: none;
  border-radius: 5px; /* Blago zaobljeni rubovi */
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease; /* Prijelaz za hover efekt */
  outline: none; /* Uklanja outline na focus */
}

.reset-pw:hover {
  background-color: #45a049; /* Tamnija nijansa za hover */
}

.reset-pw:focus {
  box-shadow: 0 0 0 2px rgba(72, 180, 97, 0.5); /* Dodaje sjenu za fokus */
}

/* Dodatna prilagodba za disabled stanje gumba */
.reset-pw:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: default;
}

.delete-button {
  background-color: #d9534f; /* Bootstrap's button danger color */
  color: white;
  margin-top: 5px;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #c9302c; /* A darker shade of red */
}

.delete-button:disabled {
  background-color: #d9534f;
  opacity: 0.65;
  cursor: not-allowed;
}

/* Base style */
.iconUser {
  width: 4vw; /* Default, for medium screens */
  max-width: 40px; /* Prevents the iconUser from becoming too large */
  min-width: 30px; /* Ensures the iconUser doesn't get too small */
  margin-left: 5px;
  cursor: auto;
}

/* Larger screens */
@media (min-width: 1024px) {
  .iconUser {
    width: 3vw; /* Slightly smaller relative width on large screens */
  }
}

/* Smaller screens */
@media (max-width: 768px) {
  .iconUser {
    width: 10vw; /* Increase for smaller screens */
    max-width: 80px; /* Larger max-width for mobile */
    min-width: 30px; /* Optional: Adjust min-width as necessary */
  }


}


/* end of css for register property */

/* CSS for dialogs */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dialog {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dialog-close {
  position: fixed;
  display: flex;
  cursor: pointer;
}

.dialog-button-positive {
  background-color: #4CAF50; /* Zelena boja za akciju */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px; /* Blago zaobljeni rubovi */
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease; /* Prijelaz za hover efekt */
  outline: none; /* Uklanja outline na focus */
  margin: 10px;
}

.dialog-button-positive:hover {
  background-color: #45a049; /* Tamnija nijansa za hover */
}

.dialog-button-positive:focus {
  box-shadow: 0 0 0 2px rgba(72, 180, 97, 0.5); /* Dodaje sjenu za fokus */
}

.dialog-button-negative {
  background-color: #d9534f; /* Bootstrap's button danger color */
  color: white;
  padding: 10px 20px;
  border: none;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dialog-button-negative:hover {
  background-color: #c9302c; /* A darker shade of red */
}

.dialog-button-negative:focus {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5); /* Dodaje sjenu za fokus */
}

/* end of CSS for dialogs */

/* CSS for propertyTable */




@keyframes scroll-animation {
  from { transform: translateY(0); }
  to { transform: translateY(-100%); } /* Adjust this value based on actual content height */
}

.dataGridContainer {
  height: 43vh;
  width: 85vw;
  margin-bottom: 60em;
  margin-left: auto;
  margin-right: auto;
}


@media (max-width: 600px) {
  .dataGridContainer {
    /* Adjust the styles for mobile, for example: */
    height: 45vh; /* Taller grid on mobile */
    width: 95vw; /* Use more of the screen width on smaller devices */
    /* margin-bottom: 28vh; Less margin on mobile */
    margin-bottom: 140em;

    /* return to 23 */
  }

  .calendarComponent {
    align-content: center;
  }
  .calendarItem {
    max-width: fit-content;
  margin-inline: auto;
  }
  
}



@media (min-width: 600px) and (max-width: 950px) {
  .dataGridContainer {
    /* Adjust the styles for tablets, for example: */
    height: 40vh; /* Shorter grid on tablets */
    width: 90vw; /* Use more of the screen width on tablets */
    margin-bottom: 80em; /* Less margin on tablets */
    
  }
}

@media (min-width: 950px) and (max-width: 1200px) {
  /* this is the part where the calendar starts to get together */
  /* added to fix the issue */
  .dataGridContainer{
    margin-bottom: 60em;
  }
  .calendarComponent {
    margin-left: auto;
    margin-right: auto;
    /* width: 87vw; */
  }
  .calendarItem{
    /* smanjiti da tu bude grid po 2 kalendara */
    width: 150px;
    background-color: black;
  
  }
}


.bookings-component {
  /* width: 50vw; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


/* Icons for PropertyTable.jsx */
.iconProperty {
  font-size: 24px;
  
}

/* Smaller size for phones */
@media (max-width: 600px) {
  .iconProperty {
    font-size: 20px; /* Adjust for phone screens */
  }
}



/* end of CSS for propertyTable */

.login h5 {
  color: black;
  /* margin-top: 2.5vh; */
}

@media (min-width: 600px) {
  .user-commands {
    margin-top: 1vh;
  }
}


/* if user is logged out display message */
.user-logged-out {
  /* put in the middle of the screen from top down and left right */
  position: absolute;
  top: 50%;
  left: 50%;
  /* translate the element -50% from the middle of the element */
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 2em;
  color: black;

}




.cellGreen {
  background-color: black !important;
  color: white !important;
}

.cellRed {
  background-color: red !important;
  color: white !important;
}


/* NotFound.jsx */

/* NotFound.css */
.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f8f8;
  color: #333;
}

.not-found-title {
  font-size: 6rem;
  margin-bottom: 0;
}

.not-found-message {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.not-found-link {
  font-size: 1.2rem;
  color: black;
  text-decoration: underline;
  /* text-decoration: none; */
  margin: 0.1rem 0;
}

.not-found-link:hover {
  font-size: 1.3rem;
}
